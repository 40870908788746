import {Button, Collapse, Nav} from "react-bootstrap";
import React, {useState} from "react";
import {Link} from "react-router-dom";

const NavbarList = ({setOpenOffcanvas, headerText, onClickFn, links}) => {
    const [openList, setOpenList] = useState(false);

    return (
        <li className={"mb-1"}>
            <Button
                variant={"none"}
                onClick={() => setOpenList(!openList)}
                aria-controls={headerText}
                aria-expanded={openList}
                className={"btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"}
            >
                {headerText}
            </Button>

            <Collapse in={openList}>
                <ul className={"btn-toggle-nav list-unstyled fw-normal pb-1 small"}>
                    {links.map((link, index) => {
                        return <li key={index}>
                            <Nav.Link
                                as={Link}
                                to={link.path}
                                onClick={() => {
                                    setOpenOffcanvas(false)
                                    if (onClickFn !== null) {
                                        onClickFn()
                                    }
                                }}
                                className={"link-dark d-inline-flex text-decoration-none rounded"}
                            >
                                {link.text}
                            </Nav.Link>
                        </li>
                    })}
                </ul>
            </Collapse>
        </li>
    )
}

export default NavbarList