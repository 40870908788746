import React from 'react'
import {matchPath, Outlet, useLocation} from 'react-router-dom'
import GreengardNavbar from "./Navbar/GreengardNavbar";


const Layout = () => {
    const location = useLocation()

    const matchesPaths = (paths) => {
        return paths.some(function (path) {
            return matchPath(
                {
                    path: path,
                    caseSensitive: false, // Optional, `true` == static parts of `path` should match case
                    end: true, // Optional, `true` == pattern should match the entire URL pathname
                },
                location.pathname
            )
        })
    }

    const excludeNavbar = () => {
        const paths = ["/login"]
        return matchesPaths(paths)
    }

    return (
        <div>
            {/* <!-- Page Wrapper --> */}
            <div id={"wrapper"}>

                {/* Content Wrapper */}
                <div id={"content-wrapper"} className={"d-flex flex-column"}>

                    {/* Main Content */}
                    <div id={"content"}>

                        {/* Navbar */}
                        {!excludeNavbar() && <GreengardNavbar/>}
                        {/* End of Navbar */}

                        {/* Begin Page Content */}
                        <Outlet/>
                        {/* End Page Content */}

                    </div>
                    {/* <!-- End of Main Content --> */}

                </div>
                {/* <!-- End of Content Wrapper --> */}

            </div>
            {/* <!-- End of Page Wrapper --> */}
        </div>
    )
}

export default Layout
