import SEO from "../components/SEO";
import {Container} from "react-bootstrap";
import Heading from "../components/Heading";

const Dashboard = () => {
    return (
        <>
            <SEO title={"Dashboard"}/>
            <Container fluid={false} className={"my-4"}>
                <Heading text={"Dashboard"}/>
            </Container>
        </>
    )
}

export default Dashboard