import {Container, Image, Navbar, Offcanvas} from "react-bootstrap";
import Logo from '../../assets/images/logo_horizontal_orange.png'
import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import NavbarList from "./NavbarList";
import {AppRoutes} from "../../appRoutes";
import AuthContext from "../../contexts/AuthProvider";

const GreengardNavbar = () => {
    const {logout} = useContext(AuthContext)
    const [openOffcanvas, setOpenOffcanvas] = useState(false);

    return (
        <Navbar expanded={openOffcanvas} bg={"dark"} variant={"dark"} expand={false} className={"mb-3"}>
            <Container fluid={false}>
                <Navbar.Toggle onClick={() => setOpenOffcanvas(openOffcanvas ? false : "expanded")}
                               aria-controls={"offcanvasNavbar"}/>

                <Navbar.Brand as={Link} to={"/"}>
                    <Image src={Logo} alt={""} height={"29"}/>
                </Navbar.Brand>

                <Navbar.Offcanvas
                    id={"offcanvasNavbar"}
                    aria-labelledby={"offcanvasNavbar"}
                    placement={"start"}
                    style={{width: "280px"}}
                    onHide={() => setOpenOffcanvas(false)}
                    backdrop={true}
                    scroll={true}
                >
                    <Offcanvas.Body className={"flex-shrink-0 p-3 bg-white"}>
                        <Offcanvas.Header closeButton onClick={() => setOpenOffcanvas(false)}
                                          className={"d-flex align-items-center pt-0 pb-3 mb-3 link-dark text-decoration-none border-bottom"}>
                            <Navbar.Brand as={Link} to={"/"}>
                                <Image src={Logo} alt={""} height={"29"}/>
                            </Navbar.Brand>
                        </Offcanvas.Header>

                        <ul className={"list-unstyled ps-0"}>
                            <NavbarList setOpenOffcanvas={setOpenOffcanvas} headerText={"Problems"} onClickFn={null}
                                        links={[
                                            {
                                                "text": "List",
                                                "path": AppRoutes.Problems.path
                                            }
                                        ]}/>

                            <NavbarList setOpenOffcanvas={setOpenOffcanvas} headerText={"Costs"} onClickFn={null}
                                        links={[
                                            {
                                                "text": "Solver runs",
                                                "path": AppRoutes.SolverRunCosts.path
                                            }
                                        ]}/>

                            <li className={"border-top my-3"}/>

                            <NavbarList setOpenOffcanvas={setOpenOffcanvas} headerText={"Account"} onClickFn={logout}
                                        links={[
                                            {
                                                "text": "Log out",
                                                "path": AppRoutes.Login.path
                                            }
                                        ]}/>

                        </ul>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}

export default GreengardNavbar