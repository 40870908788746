import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {AppRoutes} from './appRoutes'
import {HelmetProvider} from "react-helmet-async";
import Layout from "./components/Layout";
import PrivateRoute from "./components/PrivateRoute";

function App() {
    return (
        <HelmetProvider>
            <Routes>
                <Route path={AppRoutes.Login.path} element={AppRoutes.Login.element}/>

                <Route path={"/"} element={<Layout/>}>

                    {/* Private routes */}
                    <Route element={<PrivateRoute/>}>
                        <Route path={AppRoutes.Dashboard.path} element={AppRoutes.Dashboard.element}/>
                        <Route path={AppRoutes.Profile.path} element={AppRoutes.Profile.element}/>
                        <Route path={AppRoutes.SolverRunCosts.path} element={AppRoutes.SolverRunCosts.element}/>
                        <Route path={AppRoutes.Problems.path} element={AppRoutes.Problems.element}/>

                        <Route path={AppRoutes.ErrorPage.path} element={AppRoutes.ErrorPage.element}/>
                        <Route path={AppRoutes.CatchAll.path} element={AppRoutes.CatchAll.element}/>
                    </Route>

                    <Route path={AppRoutes.ErrorPage.path} element={AppRoutes.ErrorPage.element}/>
                    <Route path={AppRoutes.CatchAll.path} element={AppRoutes.CatchAll.element}/>

                </Route>
            </Routes>
        </HelmetProvider>
    )
}

export default App
