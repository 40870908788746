import CenterSpinner from "../../components/CenterSpinner";
import SEO from "../../components/SEO";
import {Button, Col, Container, Dropdown, Form, ListGroup, Row, Spinner} from "react-bootstrap";
import GreengardBreadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import ErrorModal from "../../components/ErrorModal";
import React, {useEffect, useState} from "react";
import useAxiosPrivate from "../../adapters/xhr/axiosPrivate";
import ProblemCardItem from "./ProblemCardItem";

const Problems = () => {
    const axiosPrivate = useAxiosPrivate();
    const [spinnerMessage, setSpinnerMessage] = useState(null);
    const [errors, setErrors] = useState(null);

    const [problems, setProblems] = useState([]);

    useEffect(() => {
        setSpinnerMessage("Retrieving problems..")
        axiosPrivate.get('/v1/problems/frontend/minimal/all/').then((response) => {
            if (response.status === 200) {
                const data = _sortOnLatestFirst(response.data)
                setProblems(data)
            }
            setSpinnerMessage(null);
        }).catch(error => {
            setErrors(error.response.data.errors);
            setSpinnerMessage(null);
        })
    }, [])

    const _sortOnName = (data) => {
        return [].concat(data).sort((a, b) => ('' + a.name).localeCompare(b.name))
    }

    const sortOnName = () => {
        setProblems(_sortOnName(problems))
    }

    const sortOnEarliestFirst = () => {
        const sortedProblems = [].concat(problems).sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
        setProblems(sortedProblems)
    }

    const _sortOnLatestFirst = (data) => {
        return [].concat(data).sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    }

    const sortOnLatestFirst = () => {
        setProblems(_sortOnLatestFirst(problems))
    }

    return (
        spinnerMessage
            ?
            <CenterSpinner text={spinnerMessage}/>
            :
            <>
                <SEO title={"Problems"}/>
                <Container fluid={false} className={"my-4"}>
                    <GreengardBreadcrumb items={[
                        {
                            title: "Dashboard",
                            path: "/"
                        },
                        {
                            title: "Problems",
                            path: "/problems"
                        }
                    ]}/>

                    <Heading text={"Problems"}/>


                    {problems ?
                        <>
                            <Row className="mb-2">
                                <Col className="text-end">
                                    <Dropdown>
                                        <Dropdown.Toggle size="sm" className={"btn-orange shadow-none"}>
                                            Sort by
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => sortOnName()}>
                                                Name
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => sortOnEarliestFirst()}>
                                                Date (earliest first)
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => sortOnLatestFirst()}>
                                                Date (latest first)
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>


                            {problems.map((problem, index) => (
                                <ProblemCardItem key={index} problem={problem} problems={problems}
                                                 setProblems={setProblems} setSpinnerMessage={setSpinnerMessage}
                                                 setErrors={setErrors}/>
                            ))}
                        </>
                        :
                        <p>Can't retrieve problems..</p>
                    }
                    <ErrorModal errors={errors} setErrors={setErrors}/>
                </Container>
            </>
    )
}

export default Problems