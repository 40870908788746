import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {Link} from "react-router-dom";

function GreengardBreadcrumb({items}) {
    return (
        <Breadcrumb>
            {items.map((value, index) => {
                return <Breadcrumb.Item key={index} linkAs={Link} linkProps={{to: value.path}}
                                        active={index === items.length - 1}>
                    {value.title}
                </Breadcrumb.Item>
            })}
        </Breadcrumb>
    );
}

export default GreengardBreadcrumb;