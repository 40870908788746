import React from 'react'
import Dashboard from "./pages/Dashboard";
import ErrorPage from "./pages/ErrorPage";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import SolverRunCosts from "./pages/SolverRunCosts";
import Problems from "./pages/Problems/Problems";

export const AppRoutes = {
    // pages
    SolverRunCosts: {
        element: <SolverRunCosts/>,
        path: '/solver-run-costs'
    },
    Problems: {
        element: <Problems/>,
        path: '/problems'
    },
    CatchAll: {
        element: <ErrorPage/>,
        path: '*'
    },
    Dashboard: {
        element: <Dashboard/>,
        path: '/'
    },
    ErrorPage: {
        element: <ErrorPage/>,
        path: '/error'
    },
    Login: {
        element: <Login/>,
        path: '/login'
    },
    Profile: {
        element: <Profile/>,
        path: '/profile'
    },
}
