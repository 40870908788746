import SEO from "../components/SEO";
import {Container} from "react-bootstrap";
import Heading from "../components/Heading";

const Profile = () => {
    return (
        <>
            <SEO title={"Profile"}/>
            <Container fluid={false} className={"my-4"}>
                <Heading text={"Profile"}/>
            </Container>
        </>
    )
}

export default Profile