import React, {useEffect, useState} from "react";
import useAxiosPrivate from "../adapters/xhr/axiosPrivate";
import {Button, Col, Container, Form, ListGroup, Row, Spinner} from "react-bootstrap";
import Heading from "../components/Heading";
import CenterSpinner from "../components/CenterSpinner";
import SEO from "../components/SEO";
import GreengardBreadcrumb from "../components/Breadcrumb";
import ErrorModal from "../components/ErrorModal";

const SolverRunCosts = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth() - 1, 2);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const axiosPrivate = useAxiosPrivate();
    const [spinnerMessage, setSpinnerMessage] = useState(null);
    const [errors, setErrors] = useState(null);

    const [organizations, setOrganizations] = useState([]);
    const [organization, setOrganization] = useState(null);
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(null);
    const [startDate, setStartDate] = useState(firstDayOfMonth.toISOString().substring(0, 10));
    const [endDate, setEndDate] = useState(lastDayOfMonth.toISOString().substring(0, 10));
    const [result, setResult] = useState(null);

    const fetchData = async () => {
        setSpinnerMessage("Retrieving organizations..");

        const organizationsUrl = `/v1/organizations/`;
        const usersUrl = `/v1/users/`;
        const responses = await Promise.all([
            axiosPrivate.get(organizationsUrl), axiosPrivate.get(usersUrl),
        ]).catch(error => {
            setErrors(error.response.data.errors);
            setSpinnerMessage(null);
        });

        const organizationsData = await responses[0].data;
        const usersData = await responses[1].data;

        organizationsData.sort((a, b) => a.name.localeCompare(b.name));
        usersData.sort((a, b) => a.name.localeCompare(b.name));

        setOrganizations(organizationsData);
        setUsers(usersData);
        setOrganization(organizationsData[0]);

        setSpinnerMessage(null);
    }

    useEffect(() => {
        fetchData().then(_r => {
        })
    }, [])

    useEffect(() => {
        if (users.length > 0) {
            setUser(users.find(u => u.organization.id === organization.id));
        }
    }, [organization])

    const onSubmit = async (e) => {
        setSpinnerMessage("Creating invoice..")
        e.preventDefault();

        const url = `/v1/invoices/get-invoice-for-solver-runs/?user_id=${user.id}&start_date=${startDate}&end_date=${endDate}`;
        axiosPrivate.get(url, {
            responseType: "arraybuffer"
        }).then((response) => {
            let contentDisposition = response.headers['content-disposition'];
            let startFileNameIndex = contentDisposition.indexOf('"') + 1
            let endFileNameIndex = contentDisposition.lastIndexOf('"');
            let filename = contentDisposition.substring(startFileNameIndex, endFileNameIndex);

            const bytes = new Uint8Array(response.data);
            const blob = new Blob([bytes], {type: "application/pdf"});
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();

            setSpinnerMessage(null);
        }).catch(error => {
            setErrors([error]);
            setSpinnerMessage(null);
        });
    }

    return (
        spinnerMessage
            ?
            <CenterSpinner text={spinnerMessage}/>
            :
            <>
                <SEO title={"Solver run costs"}/>
                <Container fluid={false} className={"my-4"}>
                    <GreengardBreadcrumb items={[
                        {
                            title: "Dashboard",
                            path: "/"
                        },
                        {
                            title: "Solver run costs",
                            path: "/solver-run-costs"
                        }
                    ]}/>

                    {organizations && organization && users && user
                        ?
                        <>
                            <Heading text={"Solver run costs"}/>

                            <Form onSubmit={onSubmit} className={"mt-3"}>
                                <Row>

                                    <Col md={3}>
                                        <Form.Group className={"mb-3"} controlId={"formOrganization"}>
                                            <Form.Label>Organization</Form.Label>
                                            <Form.Select defaultValue={organization.id} onChange={(e) => {
                                                const selectedOrganization = organizations.find(element => element.id.toString() === e.target.value.toString());
                                                setOrganization(selectedOrganization);
                                            }}>
                                                {organizations.map((availableOrganization, index) => {
                                                    return <option key={index} value={availableOrganization.id}>
                                                        {availableOrganization.name}
                                                    </option>
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>

                                    <Col md={3}>
                                        <Form.Group className={"mb-3"} controlId={"formUser"}>
                                            <Form.Label>Invoice reference</Form.Label>
                                            <Form.Select defaultValue={user.id} onChange={(e) => {
                                                const selectedUser = users.find(element => element.id.toString() === e.target.value.toString());
                                                setUser(selectedUser);
                                            }}>
                                                {users.filter(u => u.organization.id === organization.id).map((availableUser, index) => {
                                                    return <option key={index} value={availableUser.id}>
                                                        {availableUser.name}
                                                    </option>
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>

                                    <Col md={3}>
                                        <Form.Group className={"mb-3"} controlId={"formStartDate"}>
                                            <Form.Label>Start date</Form.Label>
                                            <input
                                                type={"date"}
                                                className={"form-control"}
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={3}>
                                        <Form.Group className={"mb-3"} controlId={"formEndDate"}>
                                            <Form.Label>End date</Form.Label>
                                            <input
                                                type={"date"}
                                                className={"form-control"}
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>

                                </Row>

                                <Button variant={"primary"} type={"submit"} disabled={spinnerMessage !== null}>
                                    Download invoice

                                    {spinnerMessage !== null ?
                                        <Spinner className={"ms-2"} animation={"border"} role={"status"} size={"sm"}>
                                            <span className={"visually-hidden"}>Creating invoice..</span>
                                        </Spinner>
                                        :
                                        <></>
                                    }
                                </Button>

                            </Form>

                            {result &&
                                <div className={"mt-4"}>
                                    <h4>Total cost: {result.total_cost}</h4>
                                    <ListGroup>
                                        {result.rows.map(((row, index) => {
                                            return <ListGroup.Item key={index}>{row}</ListGroup.Item>
                                        }))}
                                    </ListGroup>
                                </div>
                            }
                        </>
                        :
                        <p>Can't retrieve organizations..</p>
                    }
                    <ErrorModal errors={errors} setErrors={setErrors}/>
                </Container>
            </>
    )
}

export default SolverRunCosts