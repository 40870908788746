export const flatten = (arr, depth = 1) => {
    if (depth === 1) {
        return flattenArr(arr);
    }

    let res = [];

    for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
            res.push(flatten(arr[i], depth - 1));
        } else {
            res.push(arr[i]);
        }
    }
    return res;
}

const flattenArr = (arr) => {
    let res = [];
    for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
            res = res.concat(flattenArr(arr[i]))
        } else {
            res.push(arr[i]);
        }
    }
    return res;
}

export const isHourMinuteFormat = (str) => {
    return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(str);
}

export const pluralize = (number) => {
    return number === 1 ? "" : "s"
}
