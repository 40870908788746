import {Card, Col, Dropdown, DropdownButton, Row, Spinner} from "react-bootstrap";
import {FaCloudDownloadAlt, FaHourglassHalf, FaTrash, FaUpload} from "react-icons/fa";
import React, {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import useAxiosPrivate from "../../adapters/xhr/axiosPrivate";
import {pluralize} from "../../utils";

const ProblemCardItem = ({problem, problems, setProblems, setSpinnerMessage, setErrors}) => {
    const axiosPrivate = useAxiosPrivate()
    const navigate = useNavigate()

    const inputFile = useRef(null)

    const [isLoading, setIsLoading] = useState(false)

    const onDelete = (event, problemId) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        setIsLoading(true)
        axiosPrivate.get(`/v1/problems/${problemId}/delete/`).then((response) => {
            if (response.status === 200) {
                setProblems(problems.filter(p => p.id !== problemId))
            }
            setIsLoading(false)
        }).catch(error => {
            console.log(error)
            setIsLoading(false)
        })
    }

    const downloadProblemJSON = () => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(problem.json, null, 2)
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = problem.name + '.json';
        link.click();
    };

    const downloadRoutingMatrices = () => {
        setSpinnerMessage("Downloading routing matrices..")
        axiosPrivate.get(`/v1/problems/${problem.id}/download-routing-matrices/`, {
            responseType: "blob"
        }).then(({data: blob}) => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `routing_matrices_${problem.md5}.zip`;
            link.click();
            setSpinnerMessage(null);
        }).catch(error => {
            setErrors([error.response.statusText]);
            setSpinnerMessage(null);
        })
    }

    const createRoutingMatrices = () => {
        setSpinnerMessage("Creating routing matrices..")
        axiosPrivate.get(`/v1/problems/${problem.id}/create-routing-matrices/`).then((data) => {
            setSpinnerMessage(null);
        }).catch(error => {
            setErrors([error.response.statusText]);
            setSpinnerMessage(null);
        })
    }

    const uploadSolutions = (e) => {
        if (!e.target.files) {
            return;
        }

        setSpinnerMessage("Uploading solutions..")

        const files = e.target.files ? [...e.target.files] : [];
        const formData = new FormData()
        files.forEach((file, i) => {
            formData.append(`file-${i}`, file, file.name);
        });

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        axiosPrivate.post(`/v1/problems/${problem.id}/solutions/frontend/upload/`, formData, config).then((response) => {
            setSpinnerMessage(null);
        }).catch(error => {
            setErrors([error.response.statusText]);
            setSpinnerMessage(null);
        })
    }

    return (
        <Card className={"mb-2"}>
            <Card.Header>
                <div className={"d-flex justify-content-between"}>
                    {problem.name}

                    {isLoading
                        ?
                        <Spinner animation="border" role="status" size={"sm"}>
                            <span className="visually-hidden"/>
                        </Spinner>
                        :
                        <DropdownButton title={"Options"} size={"sm"} variant={"secondary"}>

                            <Dropdown.Item
                                onClick={downloadProblemJSON}
                            >
                                <FaCloudDownloadAlt className={"mb-1"}/> Download problem
                            </Dropdown.Item>

                            <Dropdown.Item
                                onClick={downloadRoutingMatrices}
                            >
                                <FaCloudDownloadAlt className={"mb-1"}/> Download routing matrices
                            </Dropdown.Item>

                            <Dropdown.Item
                                onClick={createRoutingMatrices}
                            >
                                <FaHourglassHalf className={"mb-1"}/> Create routing matrices
                            </Dropdown.Item>

                            <Dropdown.Item
                                onClick={() => inputFile.current.click()}
                            >
                                <FaUpload className={"mb-1"}/> Upload solution
                            </Dropdown.Item>
                            <input type='file' id='file' ref={inputFile} style={{display: 'none'}}
                                   onChange={(e) => uploadSolutions(e)}/>

                            <Dropdown.Item
                                onClick={(event) => onDelete(event, problem.id)}
                            >
                                <FaTrash className={"mb-1"}/> Delete
                            </Dropdown.Item>

                        </DropdownButton>
                    }
                </div>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xs={4}>
                        <p className={"mb-0"}>{problem.num_depots} depot{pluralize(problem.num_depots)}</p>
                        <p className={"mb-0"}>{problem.num_vehicles} vehicle{pluralize(problem.num_vehicles)}</p>
                        <p className={"mb-0"}>{problem.num_customers} customer{pluralize(problem.num_customers)}</p>
                    </Col>
                </Row>

                <p className={"mb-0 fw-semi-bold fs--1"}>{problem.user.name}</p>
                <p className={"mb-0 fw-semi-bold fs--2"}>{new Date(problem.created_at).toLocaleString('nb-NO')}</p>
            </Card.Body>
        </Card>

        // <ListGroup.Item action
        //                 className={"d-flex justify-content-between align-items-center mb-2"}
        //                 onClick={() => navigate(`/problems/${problem.id}`)}>
        //     <div>
        //         <p className="mb-0 font-weight-bold font-12">{problem.name}</p>
        //
        //
        //         <Badge className={"bg-dark-gray"}>
        //             <FaWarehouse
        //                 className={"text-orange"}/><small
        //             className={"font-12"}> {problem.num_depots} depot{pluralize(problem.num_depots)}</small>
        //         </Badge>
        //
        //         <Badge className={"bg-dark-gray ms-1"}>
        //             <FaTruck
        //                 className={"text-orange"}/><small
        //             className={"font-12"}> {problem.num_vehicles} vehicle{pluralize(problem.num_vehicles)}</small>
        //         </Badge>
        //
        //         <Badge className={"bg-dark-gray ms-1"}>
        //             <FaUsers
        //                 className={"text-orange"}/><small
        //             className={"font-12"}> {problem.num_customers} stop{pluralize(problem.num_customers)}</small>
        //         </Badge>
        //
        //         <br/>
        //
        //         <small
        //             className="text-gray-600 font-11"
        //         >
        //             {new Date(problem.created_at).toLocaleString('nb-NO')}
        //         </small>
        //
        //     </div>
        //
        //     {isLoading
        //         ?
        //         <Spinner animation="border" role="status" size={"sm"}>
        //             <span className="visually-hidden"/>
        //         </Spinner>
        //         :
        //         <DropdownButton title={"Options"}>
        //             <Dropdown.Item onClick={(event) => onDelete(event, problem.id)}><FaTrash/> Delete</Dropdown.Item>
        //             <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        //             <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
        //         </DropdownButton>
        //     }
        // </ListGroup.Item>
    )
}

export default ProblemCardItem